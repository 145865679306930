import React, { useEffect } from "react";
import './navigation.css'
import Logo from '../../aureateq-main-logo-white.svg'

export const Navigation = (props) => {

  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const toggleNavbarBackground = () => {
      const navbar = document.getElementById('menu');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', toggleNavbarBackground);
    return () => {
      window.removeEventListener('scroll', toggleNavbarBackground);
    };
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header" style={{ paddingBottom:`10px` }}>
          <a className="navbar-brand page-scroll" href="#header">
            <img src={Logo} alt="Aureateq" style={{ height: '50px' }} />
          </a>{" "}
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right" style={{ marginTop:`10px` }}>
            <li>
              <button onClick={() => handleScroll('header')} className="nav-link page-scroll">
                Home
              </button>
            </li>
            <li>
              <button onClick={() => handleScroll('about')} className="nav-link page-scroll">
                About
              </button>
            </li>
            <li>
              <button onClick={() => handleScroll('services')} className="nav-link page-scroll">
                Services
              </button>
            </li>
            <li>
              <button onClick={() => handleScroll('contact')} className="nav-link page-scroll">
                Contact
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
